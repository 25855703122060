/**
 * @file
 * A JavaScript file for the theme.
 *
 * In order for this JavaScript to be loaded on pages, see the instructions in
 * the README.txt next to this file.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - http://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
(function ($, Drupal, window, document, undefined) {

    Drupal.behaviors.ed_js = {
        attach: function (context, settings) {
            $('html', context).removeClass("no-js").addClass('js');
        }
    }

    Drupal.behaviors.ed_menu = {
        attach: function (context, settings) {

            if ($('#menu-btn').length === 0) {
                $('#site-header').append('<a id="menu-button" href="#navigation"><span>Menu</span></a>');

                var menuButton = $('#menu-button');
                var navigation = $('#navigation');

                navigation.addClass('has-menu-button');

                menuButton.click(function () {
                    menuButton.toggleClass('open');
                    navigation.toggleClass('open');
                    return false;
                });

                $('#skip-link a').click(function () {
                    menuButton.addClass('open');
                    navigation.addClass('open');
                });

                $('#navigation a').focus(function () {
                    menuButton.addClass('open');
                    navigation.addClass('open');
                });

            }

        }
    };


})(jQuery, Drupal, this, this.document);

/* Fonts.com Skia web font */
var MTUserId='cdb0f459-b688-4584-9b68-50d117f85cb5';
var MTFontIds = new Array();

MTFontIds.push("5197693"); // CC Skia W05 Regular
(function() {
    var mtTracking = document.createElement('script');
    mtTracking.type='text/javascript';
    mtTracking.async='true';
    mtTracking.src='/sites/all/themes/artweeks_theme/js/mtiFontTrackingCode.js';

    (document.getElementsByTagName('head')[0]||document.getElementsByTagName('body')[0]).appendChild(mtTracking);
})();